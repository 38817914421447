import React from 'react';
import { FaFacebookF, FaLinkedinIn } from "react-icons/fa";
import { AiOutlineTwitter, AiOutlineInstagram } from "react-icons/ai";
import Zoom from "react-reveal/Zoom";
import { Link } from "react-scroll";
import './footer.css';

const Footer = () => {
    return (
        <div className='container-fluid footer'>
            <div className='container'>
                <Zoom>
                    <div className='row'>
                        <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12'>
                            <div className='footer-about-section'>
                                <h6 style={{ color: "white", marginTop: "20px" }}>About</h6>
                                <p>
                                    <b>Bowfin Barb Limited</b> was established in the year 2000 as a provider of IT consultancy and software development services, based on over 16 years diverse experience in Manufacturing and Finance. Today we are still offering the same quality services and have expanded our capabilities, for example Digital Agency, Energy, Insurance &amp; Automotive.
                                </p>
                                <span className='footer-social-icons'>
                                    <a href="https://www.facebook.com/profile.php?id=100027190887120" target="Facebook">
                                        <p>
                                            <FaFacebookF size={25} />
                                        </p>
                                    </a>
                                    <a href="https://www.linkedin.com/in/stephen-joynson-462b7b2a/" target="linkedIn">
                                        <p>
                                            <FaLinkedinIn size={25} />
                                        </p>
                                    </a>
                                </span>
                            </div>
                        </div>
                        <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12'>
                            <div className='footer-service'>
                                <h6 style={{ color: "white", marginTop: "20px" }}>
                                    Services
                                </h6>
                                <div className='footer-item'>
                                    <ul>
                                        <Link to="service" spy={true} smooth={true} offset={-100} duration={100}>
                                            <li>Website Development</li>
                                        </Link>
                                        <Link to="service" spy={true} smooth={true} offset={-100} duration={100}>
                                            <li>AWS Cloud Solutions</li>
                                        </Link>
                                        <Link to="service" spy={true} smooth={true} offset={-100} duration={100}>
                                            <li>Automated Testing</li>
                                        </Link>
                                        <Link to="service" spy={true} smooth={true} offset={-100} duration={100}>
                                            <li>Insurance Systems</li>
                                        </Link>
                                        <Link to="service" spy={true} smooth={true} offset={-100} duration={100}>
                                            <li>Project Management</li>
                                        </Link>
                                    </ul>
                                </div>
                            </div>
                        </div >
                        {/*                         <div className='col-xl-4 col-lg-4 col-md-12 col-sm-12'>
                            <div className="footer-service">
                                <h6 style={{ color: "white", marginTop: "20px" }}>
                                    Customer Care
                                </h6>
                                <div className="footer-item">
                                    <ul>
                                        <li>Log In</li>
                                        <li>Media Marketing</li>
                                        <li>My Account</li>
                                        <li>Wish List</li>
                                        <li>FAQ</li>
                                    </ul>
                                </div>
                            </div>
                        </div> */}
                    </div >
                </Zoom >
            </div >
        </div >
    )
}

export default Footer 