import React, { useState } from "react";
import { Link } from 'react-scroll';
import "./navbar.css";

const Navbar = () => {
    const [navScrollColor] = useState(true)
/*     const [navScrollColor, setNavScrollColor] = useState(false);
    const onChangeNavColor = () => {
        if (window.scrollY >= 100) {
            setNavScrollColor(true)
        } else {
            setNavScrollColor(false)
        }
    }
    window.addEventListener("scroll", onChangeNavColor); */

    const [titleSmaller, setTitleSmaller] = useState(false);
    const onResizeTitle = () => {
        if (window.innerWidth < 1200) {
            setTitleSmaller(true);
        } else {
            setTitleSmaller(false);
        }
    }
    window.addEventListener("resize", onResizeTitle);

    return (
        <nav className={navScrollColor ? "navbar-scroll-color navbar-main" : "navbar-main"}>
            <div class="navbar-title">{titleSmaller ? <h2>Bowfin Barb Limited</h2> : <h1>Bowfin Barb Limited</h1>}</div>
            <ul>
                <li className="nav-item">
                    <Link to="home" spy={true} smooth={true} offset={-100} duration={100}>
                        Home
                    </Link>
                </li>
                {/*                 <li className="nav-item">
                    <Link to="about" spy={true} smooth={true} offset={-100} duration={100}>
                        About
                    </Link>
                </li> */}
                <li className="nav-item">
                    <Link to="service" spy={true} smooth={true} offset={-100} duration={100}>
                        Services
                    </Link>
                </li>
                <li className="nav-item">
                    <Link to="career" spy={true} smooth={true} offset={-100} duration={100}>
                        Clients
                    </Link>
                </li>
                {/*                 <li className="nav-item">
                    <Link to="video" spy={true} smooth={true} offset={-100} duration={100}>
                        Video
                    </Link>
                </li> */}
                <li className="nav-item">
                    <Link to="team" spy={true} smooth={true} offset={-100} duration={100}>
                        Team
                    </Link>
                </li>
                <li className="nav-item">
                    <Link to="testimonial" spy={true} smooth={true} offset={-100} duration={100}>
                        Testimonials
                    </Link>
                </li>
                {/*                 <li className="nav-item">
                    <Link to="feedback" spy={true} smooth={true} offset={-100} duration={100}>
                        Feedback
                    </Link>
                </li> */}
                <li className="nav-item">
                    <Link to="contact" spy={true} smooth={true} offset={-100} duration={100}>
                        Contact
                    </Link>
                </li>
                {/*                 <li className="nav-item">
                    <Link to="subscription" spy={true} smooth={true} offset={-100} duration={100}>
                        Subscription
                    </Link>
                </li> */}
            </ul>
        </nav>
    )
}

export default Navbar;