import React from "react";
import "./home.css";
import Typewriter from "typewriter-effect";
import Fade from 'react-reveal/Fade';
import { BsMoonStarsFill, BsFillSunFill } from 'react-icons/bs'
import { Link } from 'react-scroll';

const Home = ({ theme, handleChangeTheme }) => {
    return (
        <div className="container-fluid home" id="home">
            <div className="theme-change" onClick={() => handleChangeTheme()}>
                {theme === "light" ? (
                    <p>
                        <BsMoonStarsFill size={40} />
                    </p>
                ) : (
                    <p className="theme-icon">
                        <BsFillSunFill size={40} />
                    </p>
                )}
            </div>

            <div className="container home-content">
                <div className='row'>
                    <Fade left>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                            <h1>
                                <Typewriter
                                    options={{
                                        strings: [
                                            'Website Development',
                                            'AWS Cloud Solutions',
                                            'Automated Testing',
                                            'Insurance Systems',
                                            'Project Management'
                                        ],
                                        autoStart: true,
                                        loop: true,
                                        delay: 5
                                    }}
                                />
                            </h1>
                            <p><b>Bowfin Barb Limited</b> was established in the year 2000.</p>
                            <p>We provided IT consultancy and software development services, based on over 16 years diverse experience in Manufacturing and Finance.</p>
                            <p>Today we are still offering the same quality services and have expanded our capabilities, for example Digital Agency, Energy, Insurance &amp; Automotive.</p>
                            <Link to="service" spy={true} smooth={true} offset={-100} duration={100}>
                                <span className="view-details-home-button">
                                    View Details
                                </span>
                            </Link>
                        </div>
                    </Fade >
                    <Fade right>
                        <div className="logo col-xl-6 col-lg-6 col-md-6 col-sm-12">
                        </div>
                    </Fade>
                </div>
            </div>
        </div >
    )
}

export default Home;