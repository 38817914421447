import React from 'react';
import "./service.css";
import { FcOk } from "react-icons/fc";
import Zoom from "react-reveal/Zoom";
const Service = () => {
    const servicedata = [
        {
            id: "Website Development",
            service1: "React",
            service2: "Node.js",
            service3: "C# .NET",
            service4: "Graphical Analytics",
        },
        {
            id: "AWS Cloud Solutions",
            service1: "AWS Certified Developer Associate",
            service2: "Serverless (Lambda & ECS)",
            service3: "NoSQL (DynamoDB)",
            service4: "S3, SQS, Kinesis, Route 53 etc.",
        },
        {
            id: "Automated Testing",
            service1: "Cypress",
            service2: "Axios",
            service3: "Node.js",
            service4: null,
        },
        {
            id: "Insurance Systems",
            service1: "Our AgentIBus solution",
            service2: "Graphical Analytics & Dashboards",
            service3: "Business Analysis",
            service4: "Testing & Training",
        },
        {
            id: "Project Management",
            service1: "Project plans & cost estimation",
            service2: "Monitoring of milestones",
            service3: "Third-party supplier coordination",
            service4: "Reporting",
        }
    ];

    const colors = [
        "#AAABBC",
        "#6C91C2",
        "#B5BAB7",
        "#7CB5EC",
        "#C3C9E9",
        "#df5be7",
        "#FFE51E",
        "#36ff25",
    ];

    return (
        <div className='container service-section' id="service">
            <div className='section-title'>
                <h5>Services</h5>
                <span className='line'></span>
            </div>
            <div className='row'>
                {servicedata.map((item, index) => (
                    <Zoom top key={index}>
                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                            <div className='services'>
                                <span
                                    className="service-number"
                                    style={{ backgroundColor: colors[index] }}
                                >
                                    <p>{item.id}</p>
                                </span>
                                <div className='list-of-service'>
                                    {item.service1 ?
                                        <>
                                            <p>
                                                <FcOk size={20} style={{ margin: "5px" }} /> {item.service1}
                                            </p>
                                        </>
                                        : null
                                    }
                                    {item.service2 || item.service3 || item.service4 ?
                                        <span className="service-item-underline"></span>
                                        : null
                                    }
                                    {item.service2 ?
                                        <>
                                            <p>
                                                <FcOk size={20} style={{ margin: "5px" }} /> {item.service2}
                                            </p>
                                        </>
                                        : null
                                    }
                                    {item.service3 || item.service4 ?
                                        <span className="service-item-underline"></span>
                                        : null
                                    }
                                    {item.service3 ?
                                        <>
                                            <p>
                                                <FcOk size={20} style={{ margin: "5px" }} /> {item.service3}
                                            </p>
                                        </>
                                        : null}
                                    {item.service4 ?
                                        <>
                                            <span className="service-item-underline"></span>
                                            <p>
                                                <FcOk size={20} style={{ margin: "5px" }} /> {item.service4}
                                            </p>
                                        </>
                                        : null
                                    }
                                </div>
                            </div>
                        </div>
                    </Zoom>
                ))}
            </div>
        </div >
    );
};

export default Service