import React, { useState } from 'react';
import "./testimonial.css";
import Fade from "react-reveal/Fade";
import Bounce from "react-reveal/Bounce";
import CD from "../../Image/CD.png";
import MN from "../../Image/MN.png";
import MP from "../../Image/MP.png";
import SB from "../../Image/SB.png";
import SC from "../../Image/SC.png";
import SM from "../../Image/SM.png";
import SS from "../../Image/SS.png";

const Testimonial = () => {
    const data = [
        {
            img: SB,
            content:
                "Steve has worked for me for past 6 years at JLR, during that time he has consistently delivered quality projects, both on time while always exceeding customers expectations. He is a highly skilled full stack developer with a vast array of technical knowledge & experience. His meticulous attention to detail is second to none, and always thinks about every 'What if' scenario. This ability to second guess what the customer may require or the way they interact with the application has really given my department a competitive edge. It is truly is a great shame that his contract now has to end. I sure he wont be available for long.",
            name: "Simon Bant - March 2019",
            position: "CBO Systems Manager - Jaguar Landrover",
        },
        {
            img: MP,
            content:
                "Stephen was always thoughtful and helpful when I challenged him with new bespoke development requirements. He tackled every task with professionalism and was proactive, and hardworking. He would be an asset to any company and I have no problem recommending him.",
            name: "Mandeep Pangali - September 2012",
            position: "Account Director - Advanced Computer Software",
        },
        {
            img: SM,
            content:
                "I have known Steve for a number of years having worked with him as both his peer and his subordinate. In both roles, Steve was always an outstanding and supportive colleague and leader. His excellent communications skills mean he can deal with people at all levels within an organisation, in both a professional and empathetic way. Venturing into unfamiliar areas or technologies proves to be no issue to Steve, in fact he embraces the challenges that this presents. His pragmatic and objective approach to any given problem enables him to understand its root cause and therefore quickly focus on providing the correct resolution. This, combined with his aptitude for software development, enables him to provide first-rate software solutions. I would be happy to recommend Steve to any organisation, as he would quickly prove himself to be an asset.",
            name: "Stuart McSorley - March 2019",
            position: "Team Leader - Advanced Business Solutions",
        },
        {
            img: SC,
            content:
                "I worked with Steve for around 9 years at Advanced and always found him to be professional, approachable and always ready to assist with any problem. Steve always impressed me with his understanding of customer requirements, and his thorough approach to all customer projects. His solutions were always well-crafted, and demonstrated best practice in software development.",
            name: "Steve Chilton - March 2019",
            position: "Senior Developer - Advanced Business Solutions ",
        },
        {
            img: CD,
            content:
                "Steve worked for me both directly and indirectly whilst we were both at Total Systems. I found him to be hardworking, loyal and trustworthy, and I would not hesitate to work with again.",
            name: "Colin Davidson - September 2012",
            position: "Project Manager - Total Systems plc",
        },
        {
            img: MN,
            content:
                "I worked with Steve on a few IT projects together. He is focused, meticulous and his work is of high quality. I found I can always relied upon Steve to deliver all the work that was required and on time. It was a pleasure working with Steve and I look forward to working with him again in the future.",
            name: "Martin Ng - March 2019",
            position: "Software Engineer - Jaguar Landrover",
        },
        {
            img: SS,
            content:
                "Steve was very professional and good to work with, he has great skills as a technical engineer and in project management, specializing in C# ASP.NET MVC and SQL Server.",
            name: "Sue Seymour - August 2019",
            position: "eBIS Consultant - Advanced Business Solutions",
        },
    ];

    const [showMorePost, setShowMorePost] = useState(6);

    const loadMore = () => {
        setShowMorePost((prev) => prev + 3);
    };

    return (
        <div className='container testimonial-section' id="testimonial">
            <div className='section-title'>
                <h5>Testimonials</h5>
                <span className='line'></span>
            </div>
            <div className='row'>
                {data.slice(0, showMorePost).map((item, index) => (
                    <Fade right key={index}>
                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                            <div className="content-card">
                                <img src={item.img} alt={item.position + " named " + item.name} />
                                <p>{item.content}</p>
                                <p>
                                    <span className="name">{item.name}</span>
                                </p>
                                <p>{item.position}</p>
                            </div>
                        </div>
                    </Fade>
                ))}
            </div>
            {showMorePost >= data.length ? null : (
                <Bounce left>
                    <span className='load-more-button' onClick={loadMore}>
                        Load More
                    </span>
                </Bounce>
            )}
        </div>
    )
}

export default Testimonial