import React, { useState, useEffect } from 'react';
import DataInfo from './DataInfo';
import Pagination from '../Pagination/Pagination';
import Fade from "react-reveal/Fade";
import './career.css';

const Career = () => {
    const url = "https://vyj7kf9x47.execute-api.eu-west-2.amazonaws.com/clients";

    const [data, setData] = useState([]);

    const PER_PAGE = 7;
    const [currentPage, setCurrentPage] = useState(0);
    const handlePageClick = ({ selected: selectedPage }) => {
        setCurrentPage(selectedPage);
    };
    const offset = currentPage * PER_PAGE;
    const currentPageData = data.slice(offset, offset + PER_PAGE);
    const pageCount = Math.ceil(data.length / PER_PAGE);

    const loadPostData = () => {
        fetch(url, {
            method: "GET"
        })
            .then(res => res.json())
            .then((result) => {
                if (result) {
                    setData(result);
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    useEffect(() => {
        loadPostData();
    }, []);

    return (
        <div className="container career" id="career">
            <div className="section-title">
                <h5>Clients</h5>
                <span className="line"></span>
            </div>
            <div className='row'>
                {data && currentPageData.map((item, index) => (
                    <Fade left key={index}>
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                            <DataInfo {...item} />
                        </div>
                    </Fade>
                ))}
            </div>
            <div className='pagination-details'>
                <Pagination pageCount={pageCount} handlePageClick={handlePageClick} />
            </div>
        </div>
    )
}

export default Career