import React, { useState } from 'react';
import { GiHamburgerMenu } from 'react-icons/gi';
import { Link } from "react-scroll";
import Switch from "react-switch";
import './navbarmobileview.css';

const NavbarMobileView = ({ theme, handleChangeTheme }) => {
    const [open, setOpen] = useState(false);

    const handleNavbaropen = () => {
        setOpen(!open);
    };

    return (
        <div className='responsive-mobile-view'>
            <div className='container-fluid mobile-view-header'>
                <p>
                    <GiHamburgerMenu size={25} onClick={handleNavbaropen} />
                </p>
                <div class="navbar-mobile-title"><h1>Bowfin Barb Limited</h1></div>
            </div>
            {open ? (
                <div className="mobile-nav">
                    <ul>
                        <li className="nav-item">
                            <Link
                                to="home"
                                spy={true}
                                smooth={true}
                                offset={-100}
                                duration={100}
                            >
                                Home
                            </Link>
                        </li>
                        {/*                         <li className="nav-item">
                            <Link
                                to="about"
                                spy={true}
                                smooth={true}
                                offset={-100}
                                duration={100}
                            >
                                About
                            </Link>
                        </li> */}
                        <li className="nav-item">
                            <Link
                                to="services"
                                spy={true}
                                smooth={true}
                                offset={-220}
                                duration={100}
                            >
                                Services
                            </Link>
                        </li>

                        <li className="nav-item">
                            <Link
                                to="career"
                                spy={true}
                                smooth={true}
                                offset={-100}
                                duration={100}
                            >
                                Clients
                            </Link>
                        </li>

                        {/*                         <li className="nav-item">
                            <Link
                                to="video"
                                spy={true}
                                smooth={true}
                                offset={-100}
                                duration={100}
                            >
                                Video
                            </Link>
                        </li> */}

                        <li className="nav-item">
                            <Link
                                to="team"
                                spy={true}
                                smooth={true}
                                offset={-100}
                                duration={100}
                            >
                                Team
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link
                                to="testimonial"
                                spy={true}
                                smooth={true}
                                offset={-100}
                                duration={100}
                            >
                                Testimonials
                            </Link>
                        </li>
                        {/*                         <li className="nav-item">
                            <Link
                                to="feedback"
                                spy={true}
                                smooth={true}
                                offset={-100}
                                duration={100}
                            >
                                Feedback
                            </Link>
                        </li> */}
                        <li className="nav-item">
                            <Link
                                to="contact"
                                spy={true}
                                smooth={true}
                                offset={-100}
                                duration={100}
                            >
                                Contact
                            </Link>
                        </li>
                        {/*                       <li className="nav-item">
                            <Link
                                to="subscription"
                                spy={true}
                                smooth={true}
                                offset={-100}
                                duration={100}
                            >
                                Subscription
                            </Link>
                        </li> */}
                        <li>
                            <Switch
                                onColor="#FFFFFF"
                                offColor='#000000'
                                onHandleColor="#000000"
                                offHandleColor='#FFFFFF'
                                handleDiameter={30}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                height={20}
                                width={48}
                                className="react-switch"
                                id="material-switch"
                                onChange={handleChangeTheme}
                                checked={theme === "light"}
                            />
                        </li>
                    </ul>
                </div>
            ) : null}
        </div>
    )
}

export default NavbarMobileView