import React, { useState, createContext } from "react";
import Navbar from './components/Navbar/Navbar';
import NavbarMobileView from './components/Navbar/NavbarMobileView';
import Home from './components/Home/Home';
/* import About from './components/About/About'; */
import Service from './components/Service/Service';
import Career from './components/Career/Career';
/* import Video from './components/Video/Video'; */
import Team from './components/Team/Team';
import Testimonial from './components/Testimonial/Testimonial';
/* import Feedback from './components/Feedback/Feedback'; */
import Contact from "./components/Contact/Contact";
/* import Subscription from './components/Subscription/Subscription'; */
import Footer from './components/Footer/Footer';
import ScrollToTop from 'react-scroll-to-top';
import './App.css';

export const ThemeContext = createContext(null);

const App = () => {
  const [theme, setTheme] = useState("light");

  const handleChangeTheme = () => {
    setTheme((current) => current === "light" ? "dark" : "light")
  };

  return (
    <ThemeContext.Provider value={{ theme, handleChangeTheme }}>
      <Navbar />
      <NavbarMobileView theme={theme} handleChangeTheme={handleChangeTheme} />
      <Home theme={theme} handleChangeTheme={handleChangeTheme} />
      <div className="main-theme-components" id={theme}>
        {/* <About /> */}
        <Service />
        <Career />
        {/* <Video /> */}
        <Team />
        <Testimonial />
        {/* <Feedback /> */}
        <Contact />
      </div>
      {/* <Subscription /> */}
      <Footer />
      <ScrollToTop
        smooth
        color="white"
        height="20"
        width="20"
        style={{ borderRadius: "90px", backgroundColor: "#6C91C2" }}
      />
    </ThemeContext.Provider>
  );
}

export default App;
