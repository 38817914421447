import React from 'react';
import { FaFacebookF, FaLinkedinIn } from "react-icons/fa";
/* import { AiOutlineTwitter } from "react-icons/ai"; */
import Fade from 'react-reveal/Fade';
import SJ from "../../Image/SJ.jpg";
import './team.css';

const Team = () => {
    const data = [
        {
            id: 1,
            name: "Stephen Joynson",
            content:
                `
                I have over 30 years’ experience in software engineering and related roles, delivering at pace and to a high quality, I have worked through the full development lifecycle as both a lone worker and within numerous teams, both hands-on and as a leader.
                My software engineering background has involved me in various technologies but in the last few years my focus has been solely on Microsoft .NET and Cloud Infrastructure such as Amazon Web Services (AWS) or Microsoft Azure.               
                `,
            img: SJ
        }
    ];

    return (
        <div className="container team-members" id="team">
            <div className="section-title">
                <h5>Team Members</h5>
                <span className="line"></span>
            </div>
            <div className="row">
                {data.map((item, index) => (
                    <Fade right key={index}>
                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                            <div className="card team-members-item">
                                <img src={item.img} alt={"team member named" + item.name} />
                                <div className="team-info">
                                    <h6>{item.name}</h6>
                                    <p>{item.content}</p>
                                </div>
                                <span className="line-for-team-members"></span>
                                <div className="social-icons">
                                    <a href="https://www.facebook.com/profile.php?id=100027190887120" target="Facebook">
                                        <p>
                                            <FaFacebookF size={25} />
                                        </p>
                                    </a>
                                    <a href="https://www.linkedin.com/in/stephen-joynson-462b7b2a/" target="linkedIn">
                                        <p>
                                            <FaLinkedinIn size={25} />
                                        </p>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </Fade>
                ))}
            </div>
        </div>
    )
}

export default Team