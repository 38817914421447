import React, { useState } from 'react';
import { FcCollapse, FcExpand } from "react-icons/fc";

const DataInfo = ({ title, body }) => {
    const [show, setShow] = useState(false);

    return (
        <div className={show ? 'post-card-open' : 'post-card'}
            onClick={() => setShow(!show)}>
            <div className='collapse-option'>
                <h6>
                    {title}
                </h6>
                <p>
                    {show ? <FcCollapse size={20} /> : <FcExpand size={20} />}
                </p>
            </div>
            <div className='description'>
                {show ?
                    <div className='row'>
                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                            <p>{body.column1}</p>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                            <p>{body.column2}</p>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                            <p>{body.column3}</p>
                        </div>                                                
                    </div>
                    : null
                }
            </div>
        </div>
    )
}

export default DataInfo